/*MENU ITEM CSS MODULE*/
/*DEFAULT*/
.def,
.dense {
  line-height: 2.5rem;
  padding: 0;
  margin: 0 0.5rem;
  border-radius: 0.25rem;
}
.def > div[class*="fill"] {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.def > span[class*="icon"],
.dense > span[class*="icon"] {
  margin-bottom: auto;
  margin-top: auto;
  margin-left: 0.75rem;
  margin-right: -0.25rem;
}

.def > span[class*="menu-item-label"],
.dense > span[class*="menu-item-label"] {
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.def > span[class*="icon"] > svg {
  width: 1.25rem;
  height: 1.25rem;
}
.def > span[class*="menu-item-label"] > span[class*="icon"],
.dense > span[class*="menu-item-label"] > span[class*="icon"] {
  display: block;
}

/*DENSE*/
.dense {
  line-height: 1.75rem;
}
.dense > div[class*="fill"] {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.dense > span[class*="icon"] {
  margin-left: 0.5rem;
  margin-right: -0.125rem;
}
.dense > span[class*="menu-item-label"] {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
/*--------------*/
.def > span[class*="icon-caret-right"],
.dense > span[class*="icon-caret-right"] {
  margin-right: 0.5rem;
}
