@import "./fonts.scss";

body {
  margin: 0;
  font-family: "proxima_nova_rgregular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// html {
//   height: 100%;
//   overflow: hidden;
// }
// body,
// #root,
// #app-root {
//   height: 100%;
//   overflow: hidden;
// }
.MuiDialogContent-root {
  padding-bottom: 30px !important;
}

.pageContainer {
  padding: 2vh 1vw;
  // height: calc(100% - 4vh);
  // overflow: hidden;
  // overflow-y: scroll;
}

.docPageContainer {
  height: calc(100% - 4vh);
}

.rowFlex {
  display: flex;
  flex-direction: row;
}

.colFlex {
  display: flex;
  flex-direction: column;
}

.flexCentered {
  justify-content: center;
}

.fullHeight {
  height: 100%;
}

.fullWidth {
  width: 100%;
}

input {
  display: inline;
  width: 100%;
  font-size: 13px;
  border: none;
}

.input-hidden-label {
  height: 0;
  visibility: hidden;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

textarea {
  font-family: "proxima_nova_rgregular";
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

svg {
  cursor: pointer;
}

.loading {
  top: 40%;
  right: 45%;
  position: fixed;
}

/* width */
div::-webkit-scrollbar {
  width: 4px;
  border-radius: 6px;
}

/* Track */
div::-webkit-scrollbar-track {
  border-radius: 0px;
}

/* Handle */
div::-webkit-scrollbar-thumb {
  background: darkgray;
  border-radius: 0px;
}

/* Handle on hover */
div::-webkit-scrollbar-thumb:hover {
  border-radius: 0px;
}

.cursor {
  cursor: pointer;
}
