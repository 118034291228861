* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.form-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #d3d3d3;
  padding: 10px;
  border-radius: 0px 0px 0px 12px;
  /* overflow: auto; */
  width: 35%;
}
.mr-2 {
  margin-right: 2rem;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.red-heading,
.green-heading,
.grey-heading {
  background-color: #ffd9d9 !important;
  border-radius: 6px !important;
  border: none !important;
  width: 95%;
  margin: auto !important;
  margin-bottom: 10px !important;
}

.green-heading {
  background-color: #d0fdee !important;
}

.grey-heading {
  background-color: #eaeaea !important;
}

.section-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  border-radius: 7px;
  min-height: 4rem;
  padding: 10px;
}

.form-container .MuiAccordionDetails-root {
  display: block;
}
.form-container .MuiAccordion-root::before {
  display: none;
}
.form-container .MuiPaper-elevation1 {
  box-shadow: none;
}

.section-card ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 0;
  border-bottom: 1px solid #f0f0f0;
}

.section-card span,
.correct-details span {
  color: #2a254f;
  font-weight: 600;
  font-size: 0.9rem;
}

.correct-details span {
  max-width: 12rem;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: right;
}

.log-history span {
  color: #121212;
  font-weight: 600;
  font-size: 0.9rem;
  padding-left: 10px;
}

span.red {
  color: #ff0000 !important;
}

span.blue {
  color: #322fff !important;
}

span.black {
  color: #121212 !important;
}

span.dgreen {
  color: #006240;
}

.content-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.5rem;
}

.content-grid .item {
  display: flex;
  justify-content: space-around;
  width: 45%;
  padding: 10px 0;
}

.content-grid .item span {
  font-size: 0.8rem;
}

.correct-details ul,
.log-history ul {
  list-style: none;
}

.correct-details ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #d4d4d4;
}

.correct-details ul li:last-child {
  border-bottom: none;
}

.log-history ul li {
  margin-bottom: 0.7rem;
  margin-left: 0.7rem;
}

.cancel-btn {
  background: transparent linear-gradient(180deg, #f2f4f7 0%, #ffffffa8 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #ced0da;
  padding: 7px 15px;
  border-radius: 6px;
  margin-right: 1rem;
}

.apply-btn {
  background: #474186 0% 0% no-repeat padding-box;
  padding: 7px 15px;
  border-radius: 6px;
  color: #fff;
  border: none;
}
