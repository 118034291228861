.sap-login {
  min-height: 100vh;
  background-color: #2a254f;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sap-login h3 {
  color: #ffffff;
  font-size: 2rem;
  margin-top: 2rem;
}

.sap-login p {
  width: 40%;
  margin: 1rem 0;
  font-size: 1.2rem;
  color: #ffffff;
  text-align: center;
  opacity: 0.7;
}

.sap-login .input-sec {
  display: flex;
  flex-direction: column;
  color: #fff;
  margin-top: 1rem;
}

.sap-login .input-sec input[type='email'] {
  padding: 0.5rem;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  max-width: 20rem;
  width: 20rem;
  background-color: #fff;
}
.sap-login .input-sec span {
  display: block;
  margin: 0.7rem 0;
}

.sap-img img {
  width: 65%;
}

.sap-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  color: #fff;
  margin-top: 2rem;
}

.sap-after-login {
  margin: 1rem;
}

.sap-after-login nav {
  background-color: #2a254f;
  height: 6rem;
  align-items: center;
  display: flex;
}

.sap-after-login nav img {
  padding: 1rem;
  margin-left: 1rem;
}

.sap-after-login .rect {
  background-color: #f9fafc;
  margin-top: 2rem;
  min-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.rect .ques {
  color: #2a254f;
  font-weight: 600;
  font-size: 1.3rem;
  padding: 0.5rem 1.5rem;
}

.rect .note {
  color: #4b5660;
  opacity: 1;
  font-weight: 600;
  font-size: 0.8rem;
  width: 70%;
  padding: 0 1.5rem;
}

.btns .access {
  background: #f7d373;
  border: none;
  color: #2f2d51;
  font-weight: 600;
  border-radius: 10px;
  padding: 0.7rem 1.5rem;
  margin-right: 1rem;
  cursor: pointer;
}

.btns .link {
  text-decoration: underline;
  border: none;
  font-size: 0.8rem;
  background-color: transparent;
  color: #585858;
  margin-right: 2rem;
  cursor: pointer;
}

.sap-img a {
  color: #fff;
  text-decoration: underline;
}

.commerical-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.7rem;
  background-color: #fff;
  color: #585858;
  position: absolute;
  bottom: 0;
  align-items: center;
}

@media (min-width: 700px) and (max-width: 1024px) {
  .sap-login p {
    width: 60%;
    margin: 1.5rem 0;
    font-size: 1.1rem;
  }

  .sap-img img {
    width: 65%;
  }

  .sap-img {
    width: 80%;
  }
}
