.input-wrap label {
  display: block;
  color: #2f2d51;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0.7rem;
}

.input-wrap input[type='text'],
.input-wrap input[type='date'] {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  display: block;
  height: 40px;
  padding: 10px;
  width: 25rem;
  margin-bottom: 1rem;
}

.input-wrap input[type='text']::placeholder {
  color: #d9d9d9;
}

.input-wrap input[type='checkbox'] {
  margin-right: 0.5rem;
}

.input-wrap .terms {
  color: #2f2d51;
  font-size: 14px;
  opacity: 0.5;
}

.input-wrap .link {
  text-decoration: underline;
  cursor: pointer;
}
