.itr-filling {
  grid-template-columns: 40% 60%;
  grid-template-areas: 'item1 item2';
  gap: 2rem;
  margin: 0 5rem;
}

.itr-sec .steps {
  background-color: #f7f7f7;
  border-radius: 28px;
  width: 30%;
  display: flex;
  justify-content: space-between;
  margin: 2rem auto;
}

.itr-sec .steps span {
  color: #888888;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  display: inline-block;
}

.itr-filling .item1 {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.itr-filling .item1 h3 {
  color: #3c4853;
  margin: 1rem;
  margin-top: 2rem;
}
.itr-filling .item1 p {
  color: #d6d6d6;
  font-size: 0.7rem;
  line-height: 1rem;
  font-weight: 500;
}

.itr-filling .item1 img {
  width: 16rem;
}

.itr-filling .input-wrap input {
  width: auto;
}

.itr-filling .input-wrap {
  margin-right: 2rem;
}

.itr-filling .item2 p,
.itr-filling .item2 > label {
  color: #2f2d51;
  font-weight: 600;
  font-size: 0.8rem;
  margin-bottom: 2rem;
  display: block;
}

.itr-filling .item2 label span button {
  text-decoration: underline;
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 0.8rem;
  opacity: 0.8;
  margin: 0 0.5rem;
}

.saveBtn {
  background: #f7d373;
  padding: 0.7rem 2rem;
  color: #2f2d51;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  font-size: 0.8rem;
}
