.gstPage .main-div {
  border: 1px solid #d9d9d9;
  border-radius: 9px;
  margin: 1rem;
}

.skew-label {
  border-radius: 9px;
  background-color: #2a254f;
  border: 1px solid #2a254f;
  padding: 1rem;
  width: 10%;
  position: relative;
  height: 75px;
}

.skew-label::after {
  content: '';
  position: absolute;
  width: 100px;
  height: 75px;
  transform: skew(-15deg);
  background: #2a254f;
  border: 1px solid #2a254f;
  right: -15px;
  top: -1px;
  z-index: -1;
}

.skew-label img {
  width: 1rem;
  height: 1rem;
}

.skew-label label {
  color: white;
  margin-left: 0.7rem;
  font-size: 0.9rem;
}

.icon-label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-label span {
  display: flex;
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  justify-content: center;
  align-items: center;
  margin-right: 0.7rem;
}

.icon-label img {
  width: 1.2rem;
  height: 1.2rem;
}

.icon-label span::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #474186;
  opacity: 0.09;
  display: block;
  padding: 1rem;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
}

.icon-label .labels label {
  display: block;
}

.icon-label .labels label:first-child {
  font-size: 0.7rem;
  color: #4b5660;
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.icon-label .labels label:last-child {
  font-size: 0.85rem;
  color: #2a254f;
  font-weight: 600;
}

.icon-label.sm span {
  width: 2.2rem;
  height: 2.2rem;
}

.icon-label.sm img {
  width: 0.7rem;
  height: 0.7rem;
}

.icon-label.sm span::after {
  width: 2.2rem;
  height: 2.2rem;
}

.icon-label.sm .labels label:first-child {
  font-size: 0.6rem;
}

.icon-label.sm .labels label:last-child {
  font-size: 0.75rem;
}

.stats {
  margin: 0 3rem;
}

.gst-wrap {
  margin: 1rem;
}

.gst2a-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gst-title {
  margin: 0 0.5rem;
  margin-right: 1rem;
  color: #222222;
  font-weight: 600;
}

.gst-title.sm {
  font-size: 0.8rem;
}
.connected {
  color: #000000;
  font-size: 0.7rem;
  font-weight: 600;
  background-color: #eef7ea;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  margin-left: 4rem;
}

.connected img {
  margin-right: 0.5rem;
}
.gst2a-head .icon-label {
  margin: 0 2rem;
}

.gst2a-head .icon-label label:first-child {
  opacity: 0.8;
}

.btns-filters button {
  background: #474186;
  border: 1px solid #474186;
  padding: 0.7rem 1rem;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  font-size: 0.75rem;
  margin: 0 0.15rem;
}

.btns-filters input {
  border-radius: 5px;
  margin: 0 0.15rem;
  color: #7c7c7c;
  padding: 0.7rem 0.5rem;
  border: 1px solid #d9d9d9;
  font-size: 0.75rem;
  width: 15rem;
}

.btns-filters input::placeholder {
  color: #7c7c7c;
  opacity: 0.48;
}

.supplier_gstn {
  width: 12%;
}
.supplier_name {
  width: 12%;
}
.invoice_no {
  width: 10%;
}
.total_invoice {
  width: 12%;
}
.taxable_val {
  width: 10%;
}
.central_tax {
  width: 10%;
}
.state_tax {
  width: 10%;
}
.total_tax {
  width: 11%;
}
.filing_status {
  width: 11%;
}

.brd_color {
  width: 2%;
}

.gstPage .td {
  font-size: 0.75rem !important;
}

.gstPage .td.successBorder {
  position: relative;
  left: -16px;
  border-left: 3px solid rgb(5, 204, 5);
  height: 30px;
}

.gstPage .input-wrap input {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  color: #2a254f;
  height: 40px;
  width: 20rem;
  padding: 0 0.5rem;
}

.gstPage .input-wrap label {
  display: block;
  color: #2a254f;
  font-size: 0.8rem;
  font-weight: 600;
}

.gstPage .input-wrap,
.gstPage .btn-wrap {
  margin: 1rem 2rem;
}

.cancel {
  background: transparent linear-gradient(0deg, #f2f4f7 0%, #feffff 100%) 0% 0%
    no-repeat padding-box;
  border: 1px solid #ced0da;
  border-radius: 9px;
  color: #354052;
  padding: 10px 20px;
  font-size: 0.8rem;
}

.submitBtn {
  background: #2a254f;
  border: 1px solid #2a254f;
  border-radius: 9px;
  color: #fff;
  padding: 10px 20px;
  font-size: 0.8rem;
  margin-left: 1rem;
}

.note {
  color: #525353;
  opacity: 0.56;
  font-size: 0.65rem;
}

.note span {
  display: block;
  margin-top: 0.5rem;
  line-height: 1.2rem;
}

.dialog-content {
  padding: 2rem;
  width: 30rem;
}

.dialog-content h6 {
  text-align: center;
  color: #525353;
  margin-bottom: 2rem;
  font-size: 0.9rem;
  font-weight: 600;
}

.resend-note {
  color: #838383;
  font-weight: 600;
  font-size: 0.7rem;
  margin-bottom: 2rem;
}

.sit-back {
  color: #525353;
  font-weight: 600;
  font-size: 0.8rem;
  margin: 1.5rem 0;
  text-align: center;
}

.resend-note .resend {
  color: #2a254f;
}

.dialog-content .details {
  color: #2a254f;
  font-weight: 600;
  font-size: 0.8rem;
  width: 60%;
  display: flex;
  margin: auto;
  margin: 0.2rem 0;
}

.dialog-content .details span:first-child {
  width: 6rem;
  display: inline-block;
}

.gst-download-table {
  width: 100%;
}

.gst-download-table .table {
  width: inherit;
}

.paper-width {
  width: 750px;
  max-width: 750px !important;
}

.gst-download-table .month {
  width: 20%;
}

.gst-download-table .doc_type {
  width: 20%;
}

.gst-download-table .status {
  width: 20%;
}

.gst-download-table .last_updated {
  width: 40%;
}

.gst-download-table .table .thead .tr {
  background: #d5d5d5 0% 0% no-repeat padding-box;
  border-radius: 0;
}

.gst-download-table .table .thead .tr .th {
  color: #587293;
}

.gst-download-table .table .tbody .tr {
  margin: 0;
  box-shadow: none;
  border-radius: 0;
}

.gst-download-table .table .tbody .tr:nth-child(even) {
  background: #f1f5fa 0% 0% no-repeat padding-box;
  border: 1px solid #e3daf5;
  border-radius: 0;
}

.gstPage header {
  background: #f9f9fc;
  padding: 0.5rem;
  min-height: 60px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.gstPage img.close {
  width: 0.8rem;
  height: 0.8rem;
  position: absolute;
  right: 15px;
}

.gst-download-table img.close {
  top: 10px;
}
