.d-grid {
  display: grid;
}

.item1 {
  grid-area: item1;
}

.item2 {
  grid-area: item2;
}

.item3 {
  grid-area: item3;
}

.item4 {
  grid-area: item4;
}

.login-sec {
  grid-template-areas: 'item1 item2';
  grid-template-columns: 50% 50%;
}

.login-right {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
}

.login-right img {
  width: 12rem;
  margin-bottom: 2rem;
}

.login-right h3 {
  color: #2f2d51;
  font-size: 1.5rem;
  padding: 0.5rem 0;
}

.login-right button,
.login-right a {
  /* background: none; */
  outline: none;
  border-radius: 4px;
  border: none;
  height: 45px;
  width: 10rem;
  margin: 1rem 0;
  color: #2f2d51;
  font-weight: 600;
  display: block;
  cursor: pointer;
  text-align: center;
}

.login-right p {
  color: #2f2d51;
}

.have-acc {
  font-weight: 600;
  font-size: 0.9rem;
  opacity: 0.9;
  margin-bottom: 0.3rem;
  text-align: left;
}
.mb2 {
  margin-bottom: 2rem;
}

.email-btn {
  background-color: #f7d373 !important;
}

.phone-btn {
  background-color: #b2f3dc !important;
}

.signup-btn {
  border: 1.5px solid #112137 !important;
  background-color: white;
}

.login-left {
  background-color: #2f2d51;
  min-height: 100vh;
  position: relative;
  color: #fff;
  overflow: hidden;
  padding-left: 3rem;
}

.login-text {
  width: 80%;
  margin: auto;
}

.bar {
  width: 4rem;
  margin: 1rem 0;
  background-color: #fff;
  border: 2.5px solid #fff;
}

.login-left::after {
  content: '';
  width: 37rem;
  height: 37rem;
  border: 8rem solid rgba(255, 255, 255);
  opacity: 0.1;
  position: absolute;
  bottom: -30%;
  left: -30%;
  border-radius: 50%;
}

.login-left::before {
  content: '';
  width: 30rem;
  height: 30rem;
  border: 6rem solid rgba(255, 255, 255);
  opacity: 0.1;
  position: absolute;
  top: -35%;
  right: -35%;
  border-radius: 50%;
}

.login-text h3 {
  padding-top: 4rem;
  width: 70%;
}

.login-text p {
  padding: 1rem 0;
  width: 90%;
  opacity: 0.8;
}

.login-left img {
  width: 90%;
  height: 28rem;
}
.flex-center {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.step2 p {
  line-height: 25px;
}

.step2 {
  width: 60%;
}

.changeMb {
  color: #2f2d51;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.changeMb img {
  width: 1rem;
  height: 1rem;
  margin-bottom: 0 !important;
  padding-top: 3px;
  position: relative;
  top: 1px;
  left: -3px;
}

a.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
}

/* sign up css */

.signup-sec {
  grid-template-areas: 'item1 item2';
  grid-template-columns: 55% 45%;
}

.proceed {
  background-color: #f7d373 !important;
}

.proceed:disabled {
  opacity: 0.5;
}
