* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.home-grid {
  grid-template-columns: 32% 32% 34%;
  grid-template-areas:
    'item1 item1 item3'
    'item2 item2 item3';
  margin: 1rem;
  grid-gap: 1rem;
}

.home-grid > .item-1 {
  background: #fdfbec 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 11px #0000000f;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding: 1.5rem;
  align-items: center;
}

.home-grid > .item-2 {
  background: #f0fdfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 11px #0000000f;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding: 1.5rem;
  align-items: center;
}

.cards-grid {
  grid-template-columns: 25% 25% 50%;
  grid-template-areas:
    'item1 item1 item2'
    'item1 item1 item2';
  grid-gap: 1rem;
}

.home-card-grid {
  grid-template-columns: 50% 50%;
  grid-template-areas:
    'item1 item2'
    'item3 item4';
  margin-right: 1rem;
}

.title-sec label {
  color: #2a254f;
  font-weight: bold;
  display: block;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.title-sec span {
  color: #112137;
  opacity: 0.68;
  font-size: 1rem;
}

.home-card {
  padding: 1.5rem;
  background-color: #fff;
  width: 13rem;
  height: 9.5rem;
  border-radius: 6px;
  margin: 0.5rem;
}

.home-card img {
  display: block;
  width: 3.5rem;
  height: 3rem;
}

.home-card span {
  display: block;
  color: #2f2d51;
  font-size: 0.8rem;
  margin-top: 1rem;
}

.home-grid > .item-3 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 11px #0000000f;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding: 2rem;
  height: fit-content;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-grid > .item-3 img {
  width: 70%;
  display: block;
  margin: auto;
}

.home-grid > .item-3 p {
  color: #3c4853;
  font-weight: 600;
  line-height: 2.8rem;
  font-size: 1.8rem;
}

.home-grid > .item-3 span {
  display: block;
  margin-top: 2rem;
  color: #525353;
  opacity: 0.5;
  font-size: 0.8rem;
}
