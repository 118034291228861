.upload-logs {
  background-color: white;
  width: inherit;
  margin-top: -10px;
}

.table {
  margin: 1rem auto;
}

.upload-logs .table .thead .tr .th {
  color: #2f2d51;
  font-weight: 600;
  display: inline-block;
  padding: 0.8rem 0;
  font-size: 0.8rem;
  /* width: 200px; */
}

.upload-logs .table .tbody .tr .td {
  color: #525353;
  font-weight: 500;
  padding: 0.5rem 0;
  display: inline-block;
  /* width: 200px; */
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.upload-logs .table .thead .tr {
  background-color: #f0f0f0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.upload-logs .table .tbody .tr {
  background-color: #fff;
  border-radius: 8px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 11px #0000000f;
  border: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
}

.queued {
  color: #cc7600 !important;
}

.failed {
  color: #fd3100 !important;
}

.success {
  color: #00860b !important;
}

.partial {
  color: #c6b703 !important;
}

/* Bulk Upload CSS */

.bulk-upload-form {
  width: 60%;
}

.bulk-upload-form h2 {
  text-align: left;
  font: normal normal 600 17px/21px;
  color: #2a254f;
  opacity: 0.39;
  margin-bottom: 2rem;
}

.sample-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 0.5rem 2rem;
  color: #2a254f;
  font-weight: 600;
}
a.sample-btn:hover {
  color: #2a254f;
  text-decoration: none;
}

.label {
  color: #2a254f;
  font-weight: 600;
}

.infodwn {
  color: #58666e;
  opacity: 0.46;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mt-1 {
  margin-top: 1rem;
}
.mt-3 {
  margin-top: 3rem;
}
.infodwn2 {
  color: #525353;
  font-weight: 600;
  font-size: 13px !important;
}
.filewrap {
  position: relative;
}
.filewrap input {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  height: 40px;
  width: 25rem;
}

.filewrap button {
  position: absolute;
  right: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 40px;
  color: #4b5660;
  padding: 0 1rem;
  cursor: pointer;
}

.err {
  color: red;
  font-size: 12px;
  margin-top: 0.5rem;
}

.cancel-btn {
  background: transparent linear-gradient(180deg, #f2f4f7 0%, #ffffffa8 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #ced0da;
}

.submit-btn {
  background: #474186 0% 0% no-repeat padding-box;
  border: none;
  color: #fff;
}

.cancel-btn,
.submit-btn {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
}

.uploading-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e2e2e2;
  border-radius: 20px;
  margin: 2rem;
  padding: 1rem;
  width: 40%;
  float: right;
  position: relative;
}

.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.uploading-card h3 {
  color: #363636 !important;
}

.uploading-card h3 span {
  color: #3f8dfb;
}

.upload-progress {
  width: 90%;
  margin: auto;
  height: 5px;
  background-color: rgba(128, 128, 128, 0.3);
  border-radius: 6px;
}

.uploading-card span.infodwn {
  color: #363636;
  font-size: 12px;
  display: block;
  width: 90%;
  margin: auto;
  margin-bottom: 0.2rem;
}
