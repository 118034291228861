*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiDialog-root {
  top: 80px !important;
}
.form-groups1 p {
  font-size: 14px;
  line-height: 20px;
}
.MuiBox-root p {
  font-size: 14px;
  line-height: 20px;
}
.MuiTab-root {
  width: 180px;
}

.upload-tabs {
  list-style: none;
  display: flex;
}

.upload-tabs li {
  padding: 10px;
  color: #2a254f;
  cursor: pointer;
}

.upload-tabs .active {
  font-weight: 600;
  border-bottom: 3px solid #2a254f;
  cursor: auto;
}

.tabs__div {
  display: flex;
  margin: 2rem;
}

.m-2 {
  margin: 2rem;
}

.m-1 {
  margin: 1rem;
}

.mb1 {
  margin-bottom: 2rem;
}

.fileName {
  width: 20%;
}

.dateTime {
  width: 15%;
  margin-left: 1rem;
}

.fileType {
  width: 15%;
}

.remarks {
  width: 25%;
}

.status {
  width: 10%;
}

.report {
  width: 10%;
}

.rotate {
  transform: rotate(180deg);
}

.pagination label {
  color: #525353;
  font-size: 14px;
}

.pagination button {
  background: none;
  border: 1px solid #9b9b9b;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  padding: 0.5rem 0.8rem;
  margin: 0.5rem;
  color: #525353;
  font-size: 14px;
}

.pagination {
  margin: 1rem 0;
  float: right;
  clear: right;
}

.upload-invoice {
  background: #474186 0% 0% no-repeat padding-box;
  border: 1px solid #474186;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  color: #fff;
}

.clear-btn {
  background: transparent linear-gradient(180deg, #f2f4f7 0%, #ffffffa8 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #ced0da;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-right: 1rem;
}

button:disabled {
  opacity: 0.5;
}

.logo-wrapper {
  width: 150px;
  margin-right: 3rem !important;
}

.links-wrapper {
  margin-right: 0 !important;
  width: 100%;
}

.links-wrapper > div {
  width: 100%;
}

.links a,
.links button {
  color: #9d9bae;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  font-size: 0.85rem;
}

.links button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.links a:hover {
  color: #fff;
}

.links a.selected,
.links button.selected {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 27px;
  color: #2f2d51;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #2f2d51;
  font-weight: 600;
  color: white;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.m1 {
  margin: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.d-flex {
  display: flex;
}

.w100 {
  width: 100%;
}

.w90 {
  width: 90%;
}

.w70 {
  width: 70%;
}

.notification-wrap {
  background-color: transparent;
  border: 3px solid white;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-wrap {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000008;
  border-radius: 24px;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  height: 2.5rem;
}

.select-wrap select {
  background-color: transparent;
  border: none;
}

.ham {
  background: #2f2d51;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  color: white;
  font-size: 0.8rem;
}

.ham span {
  display: block;
  background-color: #fff;
  height: 0.8px;
  width: 14px;
  margin-bottom: 4px;
}

.ham span:last-child {
  margin-bottom: 0;
}

.select-wrap .name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  white-space: nowrap;
  margin: 0 0.5rem;
}

.select-wrap .down-arrow {
  margin-right: 0.5rem;
  cursor: pointer;
}

.d-grid {
  display: grid;
}

.item-1 {
  grid-area: item1;
}

.item-2 {
  grid-area: item2;
}

.item-3 {
  grid-area: item3;
}

.item-4 {
  grid-area: item4;
}

.p-relative {
  position: relative;
}

.user-menu {
  position: absolute;
  right: -60px;
  top: 56px;
  height: calc(100vh - 75px);
  background-color: #fff;
  width: 22rem;
  border-left: 1px solid #d9d9d9;
}

.user-menu::before {
  content: '';
  position: absolute;
  right: 60px;
  top: -5px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
}

.gstn-sec .user-menu {
  right: -17rem;
}

.gstn-sec .user-menu::before {
  right: 20rem;
}

.bottom {
  position: absolute;
  bottom: -15rem;
}

.head-sec,
.content-sec {
  padding: 1.5rem;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.head-sec .grey {
  font-size: 0.9rem;
  margin-top: 0.5rem;
  color: #4b5660;
}

.head-sec::after {
  content: '';
  height: 1px;
  background-color: #707070;
  width: 80%;
  opacity: 0.3;
  position: absolute;
  bottom: -10px;
}

.content-sec ul {
  list-style: none;
}

.content-sec ul li {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  color: #2a254f;
  font-weight: 600;
  font-size: 0.85rem;
}

.content-sec ul li img {
  margin-right: 8px;
}

.cursor {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}
.w40 {
  width: 40%;
}
.w50 {
  width: 50%;
}
.reconsilation-links {
  margin: 20px;
}

.reconsilation-links .links {
  display: block;
  margin: 10px;
}

.sap_brocher {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  overflow: hidden;
}

.sap_brocher embed {
  width: inherit;
  height: inherit;
}

.reconsilation-links select {
  background-color: white;
  border: 1px solid #2f2d51;
  margin-left: 1rem;
  height: 2rem;
}

.gst-opts {
  display: block;
  position: absolute;
  top: 30px;
  background-color: white;
  z-index: 10;
  right: 0;
  width: 100%;
  box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.3);
  padding-bottom: 0.3rem;
  border-radius: 10px;
}

.reconsilation-links .gst-opts {
  top: 40px;
}

.link-dropdown {
  display: inline-block;
  position: relative;
}

.link-dropdown .gst-opts {
  width: 20rem;
  top: 35px;
}

ul {
  list-style: none;
}

.gst-opts li {
  padding: 0.5rem 1rem;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

.gst-opts li:last-child {
  border-bottom: none;
}

.sso-menu .border-tl {
  border-top: 6px solid #fff;
  border-left: 6px solid #fff;
}

.sso-menu .border-tr {
  border-top: 6px solid #fff;
  border-right: 6px solid #fff;
}

.sso-menu .border-bl {
  border-bottom: 6px solid #fff;
  border-left: 6px solid #fff;
}

.sso-menu .border-br {
  border-bottom: 6px solid #fff;
  border-right: 6px solid #fff;
}

.sso-menu span[class*='border'] {
  border-radius: 4px;
  width: 0.8rem;
  height: 0.8rem;
  margin: 1.5px;
}

.sso-menu span {
  display: block;
}

.sso-menu span.title {
  color: #2a254f;
  font-weight: 600;
  margin-bottom: 5px;
}

.sso-menu span.title ~ span {
  margin-bottom: 1rem;
  cursor: auto;
}

.sso-menu {
  display: flex;
  flex-wrap: wrap;
  width: 2rem;
  cursor: pointer;
  position: relative;
}

.sso-menu .user-menu {
  top: 40px;
  height: fit-content;
  border-radius: 11px;
  padding: 1rem;
}
.sso-menu .user-menu::before {
  right: 70px;
}
.sso-menu .user-menu::after {
  content: '';
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: block;
  top: 0;
  left: 0;
  position: fixed;
  z-index: -1;
}
